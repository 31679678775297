import type { DynamicMetricsQuery } from "~/types/api";
import { useLoaderData } from "@remix-run/react";
import clsx from "clsx";
import _ from "lodash";
import Chart from "react-google-charts";
import { GOOGLE_CHART_COLORS } from "~/components/chart";
import Panel from "~/components/panel";
import { formatDate } from "~/utils/dates";
import { formatMoney, formatNumber } from "~/utils/formatting";

type Props = {
  height?: string;
  barLabel: string;
  chartLabel: string;
  goalField: string;
  actualField: string;
  targetField?: string;
  format?: "Accounting" | "Number" | "Percentage";
  className?: string;
  footer?: React.ReactNode;
  underIsGood?: boolean;
};

export default function GenericBarChart({
  height = "251px",
  barLabel,
  chartLabel,
  goalField,
  actualField,
  targetField,
  format,
  className,
  footer,
  underIsGood
}: Props) {
  const { dynamicMetrics } = useLoaderData<{
    dynamicMetrics: DynamicMetricsQuery["dynamicMetrics"];
  }>();

  const items: (string | number | { role: string })[][] = [
    [
      "Label",
      barLabel,
      { role: "annotation" },
      { role: "style" },
      ...(targetField
        ? ["Target", { role: "annotation" }, { role: "style" }]
        : []),
      "Goal",
      { role: "annotation" },
      { role: "style" }
    ]
  ];

  for (const goal of _.sortBy(dynamicMetrics, "date").filter(
    (g) => g.name === goalField
  )) {
    const a = parseFloat(
      dynamicMetrics.find((g) => g.date === goal.date && g.name === actualField)
        ?.sum || "0"
    );
    const b = targetField
      ? parseFloat(
          dynamicMetrics.find(
            (g) => g.date === goal.date && g.name === targetField
          )?.sum || "0"
        )
      : 0;
    const c = parseFloat(goal.sum || "0");
    const formatter =
      format === "Accounting"
        ? (value: number) => formatMoney(value, { decorator: true })
        : format === "Percentage"
          ? (value: number) =>
              formatNumber(value, { format: "0.0%", zeroes: true })
          : (value: number) => formatNumber(value, { zeroes: true });

    const comparisonNum = targetField ? b * 0.9 : c;
    const missed = underIsGood ? a > comparisonNum : a < comparisonNum;
    items.push([
      formatDate(goal.date, { format: "MMM YYYY" }),
      a,
      `Actual: ${formatter(a)}`,
      missed ? GOOGLE_CHART_COLORS.missed : GOOGLE_CHART_COLORS.actual,
      ...(targetField
        ? [b, `Target: ${formatter(b)}`, GOOGLE_CHART_COLORS.target]
        : []),
      c,
      `Goal: ${formatter(c)}`,
      GOOGLE_CHART_COLORS.goal
    ]);
  }

  const maxValue =
    _.sortBy(
      items
        .slice(1)
        .flatMap((i) => (targetField ? [i[1], i[4], i[7]] : [i[1], i[4]]))
    ).at(-1) || 0;

  return (
    <Panel className={clsx("mb-0", className)}>
      <Panel.Header title={chartLabel} className="text-center" />
      <Chart
        height={height}
        width="auto"
        chartType="BarChart"
        data={items}
        options={{
          chartArea: {
            top: 15,
            right: 15,
            bottom: 15,
            left: 70,
            height: "100%",
            width: "100%"
          },
          bar: {
            groupWidth: "90%"
          },
          hAxis: {
            minValue: 0,
            viewWindow: {
              min: 0,
              max: maxValue
            },
            ticks: [],
            textPosition: "none"
          },
          legend: "none"
        }}
      />
      {footer}
    </Panel>
  );
}
